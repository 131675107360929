import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Button, Card, Container } from 'react-bootstrap';
import Mark, { MarkOptions } from 'mark.js';
import LoadingOverlay from 'react-loading-overlay';
import { StringParam, useQueryParam } from 'use-query-params';

import Layout from '../components/Layout';
import Search from '../components/shared/Search/search';
import { cmsCollections, SearchRecords } from '../services/cmsService';
import { IGuidelineSearchResult, IGuidelineSearchResultSection } from '../models/entityModels';
import { loaderStyle, waitingText } from '../constants/common';
import SEO from "../components/seo";


const SearchGuidelines = ({data}) => {
    const [ searchResult, setSearchResult ] = useState([] as IGuidelineSearchResult[])
    const [ isLoading, setIsLoading ] = useState(false);

    const [ term, setTerm ] = useQueryParam("term", StringParam);

    const { searchguidelinesTitle, searchguidelinesDescription } = data?.seo?.nodes[0];

    useEffect(() => {
        const fn = async () => {
            if (term) {
                applySearchTerm(term);
            }
        };

        fn();
    }, [term]);

    const applySearchTerm = async (term: string) => {
        setIsLoading(true);
        setSearchResult(null);
        const data = await SearchRecords('', cmsCollections.Guidelines, term, 10).then((res: any) => res ? res.data : []);
        setIsLoading(false);
        setSearchResult(data || []);
        makeBold(term);
        setTerm(term);
    };

    const makeBold = (term: string) => {
        const context = document.querySelector(".gl-search-result");
        const instance = new Mark(context as any);
        const options: MarkOptions = {
            className: 'gl-mark',
            accuracy: 'partially'
        };

        instance.unmark();
        instance.mark(term, options);
    };

    const navigationLink = (gl: IGuidelineSearchResult, sec: IGuidelineSearchResultSection) => (
        `/guidelines?link=${gl.guidelineLink}#${sec.sectionLink}`
    );
    
    return (
        <Layout>
            <SEO title={searchguidelinesTitle} description={searchguidelinesDescription}></SEO>
            <Container className="search-guidelines" key="search-guideline">
                <h4>Search Guidelines</h4>
                <Search key="search"
                        default={term}
                        onSearchByTerm={applySearchTerm}
                        minTermLength={0}
                />

                <h5>Search Result</h5>
                <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                    <div className="gl-search-result">
                    {
                        !searchResult ? <></> :
                        !searchResult.length ? <div className="no-records">No records found</div> :
                        searchResult?.map((guideline: IGuidelineSearchResult, g: number) => (
                            <Accordion key={g} defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        {guideline.guidelineTitle}
                                    </Accordion.Toggle>
                                </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        {
                                            guideline.sections.map((section: IGuidelineSearchResultSection, s: number) => (
                                                <>
                                                    <div className="gl-search-result-section">
                                                        <Link key={s} to={navigationLink(guideline, section)}>{section.sectionTitle}</Link>
                                                    </div>
                                                    {
                                                        section.matchedLines.map((line: string, l: number) => (
                                                            <div key={l} className="gl-search-result-line">{line} ...</div>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ))
                    }
                </div>
                </LoadingOverlay>
            </Container>
        </Layout>
    );
};

export const query = graphql`
  {
    seo: allStrapiSeo {
        nodes {
          searchguidelinesDescription
          searchguidelinesTitle
        }
      },
  }
`

export default SearchGuidelines;